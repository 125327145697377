<template>
  <div
    class="ml-1 ml-md-0 mr-1 mr-md-0 mt-1 mb-3 mt-md-5 mb-mb-5 row event-card"
  >
    <div class="col-12 col-md-4">
      <img
        v-if="!event.thumbnail_url"
        class="w-100"
        src="https://dummyimage.com/350x350/fff/aaa"
        alt=""
      />
      <img v-else class="w-100 rounded" :src="event.thumbnail_url" alt="" />
    </div>
    <div class="col-12 col-md-8">
      <div class="row">
        <div class="col">
          <h3 class="pt-2 pt-md-0 pb-2 pb-md-0 h3 font-weight-bold">
            {{ event.title }}
          </h3>
          <h5 class="text-justify text-md-left">{{ eventShortDescription }}</h5>
          <p class="badge badge-primary">{{ event.type }}</p>
          <p class="text-justify text-md-left">
            {{ eventDescription }}
            <router-link :to="{ name: 'event', params: { slug: event.slug } }">
              more info
            </router-link>
          </p>
        </div>
      </div>

      <div class="row">
        <h5 class="col h5 font-weight-bold mb-3">
          <event-date :timestamp="event.timestamp" timezone="Asia/Tokyo" />
        </h5>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <a :href="event.ticket_url" v-if="pastDate">
            <button class="ml-0 btn btn-danger w-100" :disabled="pastDate">
              Event Expired
            </button>
          </a>
          <a :href="event.ticket_url" v-else>
            <button class="ml-0 btn btn-danger w-100" v-if="event.price">
              Buy Tickets for
              <event-price :price="event.price" :currency="event.currency" />
            </button>
            <button class="ml-0 btn btn-danger w-100" v-else>
              Register for this event for FREE!
            </button>
          </a>
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <router-link :to="{ name: 'event', params: { slug: event.slug } }">
            <button class="ml-0 btn btn-outline-primary w-100">
              Event Information
            </button>
          </router-link>
          <hr class="d-block d-md-none" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventDate from "@/views/components/Events/EventDate";
import EventPrice from "@/views/components/Events/EventPrice";
import dayjs from "dayjs";

export default {
  name: "EventItem",
  components: { EventPrice, EventDate },
  props: {
    event: {
      required: true,
      type: Object,
    },
  },
  computed: {
    eventDescription() {
      const limit = 240;
      if (this.event.description.length < limit) {
        return this.event.description;
      } else {
        return this.event.description.substring(0, limit) + "...";
      }
    },
    eventShortDescription() {
      const limit = 205;
      if (this.event.short_description.length < limit) {
        return this.event.short_description;
      } else {
        return this.event.short_description.substring(0, limit) + "...";
      }
    },
    pastDate() {
      const now = dayjs();
      const event = dayjs.unix(this.event.timestamp);
      return now.isAfter(event);
    },
  },
};
</script>

<style scoped lang="scss">
.event-card {
  min-height: 350px;
}
</style>
