<template>
  <div>
    <div
      class="container-fluid bujin-seminar-banner my-0 pt-lg pb-md-3 px-sm"
    ></div>
    <div class="container">
      <div class="row mt-md-5">
        <div class="col">
          <h2 class="font-weight-bold text-center text-md-left">
            Seminars & Events
          </h2>
        </div>
      </div>
      <div class="row" v-for="event in currentEvents" :key="event.id">
        <event-item :event="event" v-if="event.enabled"></event-item>
      </div>
      <div class="row mt-md-5">
        <div class="col">
          <h3
            class="text-center text-md-left"
            v-if="previousEvents.count === 1"
          >
            Previous Event
          </h3>
          <h3 class="text-center text-md-left" v-else>Previous Events</h3>
        </div>
      </div>
      <div class="row" v-for="event in previousEvents" :key="event.id">
        <event-item :event="event" v-if="event.enabled"></event-item>
      </div>
    </div>
  </div>
</template>

<script>
import EventItem from "@/views/components/Events/EventItem";
import EventsDataSource from "@/EventDataSouce.json";
import dayjs from "dayjs";
export default {
  name: "Events",
  components: { EventItem },
  data() {
    return {
      eventsData: EventsDataSource,
    };
  },
  computed: {
    currentEvents() {
      const now = dayjs();
      return this.eventsData
        .filter((event) => now.isBefore(dayjs.unix(event.timestamp)))
        .sort((a, b) => a.timestamp - b.timestamp);
    },
    previousEvents() {
      const now = dayjs();
      return this.eventsData
        .filter((event) => now.isAfter(dayjs.unix(event.timestamp)))
        .sort((a, b) => b.timestamp - a.timestamp);
    },
  },
};
</script>

<style lang="scss" scoped>
.bujin-seminar-banner {
  background-image: url("~@/assets/img/banner-events_1920x360-min.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: 768px) {
    min-height: 360px;
  }
}

.karateka-image {
  height: 400px;
  margin-top: -120px;
  @media (min-width: 768px) {
    position: absolute;
    left: -20px;
    bottom: -4rem;
    height: 160%;
    width: auto;
    margin-top: 0;
  }
  @media (min-width: 992px) {
    left: 0;
    height: 165%;
  }
}
</style>
